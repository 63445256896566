import './index.css';
import { CheckIcon } from '@heroicons/react/outline'

function processTime(time) {
    function formatTime(ms) {
        const second = Math.floor((ms % (1000 * 60)) / 1000);
        const minute = Math.floor(ms / (1000 * 60));
        if (minute === 0) {
            return second + "秒";
        } else {
            return minute + "分" + second + "秒";
        }
    }

    let nowQid = time[0], nowTime = time[1];
    const finalTime = time[time.length - 1];
    let qidTime = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0
    };
    for (let i = 2; i < (time.length - 1); i += 2) {
        qidTime[nowQid] += time[i] - nowTime;
        nowTime = time[i];
        nowQid = time[i + 1];
    }
    qidTime[nowQid] += finalTime - nowTime;

    let sumTime = 0;
    for (let i = 0; i <= 6; i++) {
        sumTime += qidTime[i];
        qidTime[i] = formatTime(qidTime[i]);
    }
    sumTime = formatTime(sumTime);
    return [qidTime, sumTime];
}

function Report({ time, resp }) {
    const [qidTime, sumTime] = processTime(time);
    return (
        <div className='bg-base-100 p-6 flex flex-col'>
                <div className="stats shadow mx-auto mb-6">
                    <div className="stat">
                        <div className="stat-title">答题总用时</div>
                        <div className="stat-value">{sumTime}</div>
                        {/* <div className="stat-desc">21% more than last month</div> */}
                    </div>
                    <div className="stat">
                        <div className="stat-title">第一题用时</div>
                        <div className="stat-value">{qidTime[2]}</div>
                    </div>
                    <div className="stat">
                        <div className="stat-title">第二题用时</div>
                        <div className="stat-value">{qidTime[3]}</div>
                    </div>
                    <div className="stat">
                        <div className="stat-title">第三题用时</div>
                        <div className="stat-value">{qidTime[4]}</div>
                    </div>
                    <div className="stat">
                        <div className="stat-title">第四题用时</div>
                        <div className="stat-value">{qidTime[5]}</div>
                    </div>
                    <div className="stat">
                        <div className="stat-title">第五题用时</div>
                        <div className="stat-value">{qidTime[6]}</div>
                    </div>
                </div>

            <div className="w-1/2 bg-blue-100 mx-auto p-6 mb-6">
                <p className="text-lg font-bold mb-6">第一题</p>
                <p className="mb-2">如果在一开始有80%的人接种了疫苗，下列哪个（些）选项与人群中一个或多个个体发生的变化吻合？</p>
                <table className="table w-1/2 mx-auto">
                    <tbody>
                    <tr>
                        <th className="text-center">作答</th>
                        <th className="text-center">参考答案</th>
                        <th className="text-center">在第0天的状态</th>
                        <th className="text-center">在第60天的状态</th>
                    </tr>
                    <tr>
                        <td>{resp[0][0]? <CheckIcon className="h-6 w-6 mx-auto"/> : ""}</td>
                        <td><CheckIcon className="h-6 w-6 mx-auto" /></td>
                        <td><img src={process.env.PUBLIC_URL + '/susceptible.png'} alt="接种疫苗" className="mx-auto" /></td>
                        <td><img src={process.env.PUBLIC_URL + '/susceptible.png'} alt="接种疫苗" className="mx-auto" /></td>
                    </tr>
                    <tr>
                        <td>{resp[0][1]? <CheckIcon className="h-6 w-6 mx-auto"/> : ""}</td>
                        <td></td>
                        <td><img src={process.env.PUBLIC_URL + '/susceptible.png'} alt="接种疫苗" className="mx-auto" /></td>
                        <td><img src={process.env.PUBLIC_URL + '/recovered.png'} alt="接种疫苗" className="mx-auto" /></td>
                    </tr>
                    <tr>
                        <td>{resp[0][2]? <CheckIcon className="h-6 w-6 mx-auto"/> : ""}</td>
                        <td><CheckIcon className="h-6 w-6 mx-auto" /></td>
                        <td><img src={process.env.PUBLIC_URL + '/recovered.png'} alt="接种疫苗" className="mx-auto" /></td>
                        <td><img src={process.env.PUBLIC_URL + '/susceptible.png'} alt="接种疫苗" className="mx-auto" /></td>
                    </tr>

                    <tr>
                        <td>{resp[0][3]? <CheckIcon className="h-6 w-6 mx-auto"/> : ""}</td>
                        <td><CheckIcon className="h-6 w-6 mx-auto" /></td>
                        <td><img src={process.env.PUBLIC_URL + '/recovered.png'} alt="接种疫苗" className="mx-auto" /></td>
                        <td><img src={process.env.PUBLIC_URL + '/recovered.png'} alt="接种疫苗" className="mx-auto" /></td>
                    </tr>
                    <tr>
                        <td>{resp[0][4]? <CheckIcon className="h-6 w-6 mx-auto"/> : ""}</td>
                        <td></td>
                        <td><img src={process.env.PUBLIC_URL + '/recovered.png'} alt="接种疫苗" className="mx-auto" /></td>
                        <td><img src={process.env.PUBLIC_URL + '/infectious.png'} alt="接种疫苗" className="mx-auto" /></td>
                    </tr>

                    </tbody>
                </table>
            </div>

            <div className="w-1/2 bg-blue-100 mx-auto p-6 mb-6">
                <p className="text-lg font-bold mb-6">第二题</p>
                <p className="font-bold">作答</p>
                <p className="font-bold text-center mb-2">接种疫苗的比例</p>
                <div className="flex justify-center gap-6 mb-4">
                    <div className="text-center">
                        {(resp[1][0] === 0) ? <div className="bg-base-200" style={{ width: '100px', height: '43px' }} /> : <img src={process.env.PUBLIC_URL + `/curve${resp[1][0]}.png`} alt={`曲线${resp[1][0]}`} />}
                        最低
                    </div>
                    <div className="text-center">
                        {(resp[1][1] === 0) ? <div className="bg-base-200" style={{ width: '100px', height: '43px' }} /> : <img src={process.env.PUBLIC_URL + `/curve${resp[1][1]}.png`} alt={`曲线${resp[1][1]}`} />}
                        ------->
                    </div>
                    <div className="text-center">
                        {(resp[1][2] === 0) ? <div className="bg-base-200" style={{ width: '100px', height: '43px' }} /> : <img src={process.env.PUBLIC_URL + `/curve${resp[1][2]}.png`} alt={`曲线${resp[1][2]}`} />}
                        最高
                    </div>
                </div>
                <p className="mb-6">
                    随着接种疫苗的人口所占百分比的提高，被感染个体变化曲线的峰值将会<span className="font-bold underline">{resp[1][3]}</span>
                    并且将有<span className="font-bold underline">{resp[1][4]}</span>峰值。
                </p>

                <p className="font-bold">正确作答典型样例</p>
                <p className="font-bold text-center mb-2">接种疫苗的比例</p>
                <div className="flex justify-center gap-6 mb-4">
                    <div className="text-center">
                        <img src={process.env.PUBLIC_URL + '/curve2.png'} alt="曲线2" />
                        最低
                    </div>
                    <div className="text-center">
                        <img src={process.env.PUBLIC_URL + '/curve1.png'} alt="曲线1" />
                        ------->
                    </div>
                    <div className="text-center">
                        <img src={process.env.PUBLIC_URL + '/curve3.png'} alt="曲线3" />
                        最高
                    </div>
                </div>
                <p>
                    随着接种疫苗的人口所占百分比的提高，被感染个体变化曲线的峰值将会<span className="font-bold underline">推迟出现</span>
                    并且将有<span className="font-bold underline">更低的</span>峰值。
                </p>
            </div>

            <div className="w-1/2 bg-blue-100 mx-auto p-6 mb-6">
                <p className="text-lg font-bold mb-6">第三题</p>
                <p className="font-bold">作答</p>
                <p className="mb-6"> {resp[2][0]} <br /> {resp[2][1]} </p>
                <p className="font-bold">正确作答典型样例</p>
                <ul className="list-disc mb-6">
                    <li>[...传染性更弱] 人们生病的速度会更慢。</li>
                    <li>[...传染性更弱] 我们可以看到被传染的人数增长得不快，30%的比率不算高。</li>
                    <li>[未选择] 模拟中的疾病的传染出现得更快且更强，相比之下，X病的传染性没有那么强且出现的时间比较晚。</li>
                    <li>[...传染性更弱] 人们处在易受感染期的时间越来越长。</li>
                    <li>[...传染性更弱] 与模拟相比较，生病的人数达到最多的时间更短。</li>
                    <li>[...传染性更弱] 蓝色和绿色的曲线后来相交了。</li>
                    <li>[...传染性更弱] 得X病的人刚开始变虚弱的时候其他疾病已经好了。</li>
                    <li>[...传染性更弱] 曲线的峰值更小，来得更晚。</li>
                    <li>[...传染性更弱] 由于X疾病有25%的峰值，模拟的疾病有40%。</li>
                </ul>
                <p className="font-bold">错误作答典型样例</p>
                <ul className="list-disc">
                    <li>[...传染性更强] 曲线越来越高</li>
                    <li>[未选择] 受感染的人会越来越少。但是差不多是同一时间停止。</li>
                    <li>[...传染性更弱] 传染性比较小的疾病不容易被传染。</li>
                    <li>[...传染性更强] 患有其他疾病的人变得更加虚弱。</li>
                    <li>[...传染性更弱] 模拟器中传染性的曲线没有X病传染性曲线来得重要。</li>
                    <li>[...传染性更弱] 感染X病的人数比实验模拟中显示的感染上疾病的人数少一些。</li>
                    <li>[...传染性更弱] X疾病感染了25%的人数，而另一种感染了40%的人数。</li>
                    <li>[...传染性更弱] 由于模拟中的疾病传染了40%，而X疾病传染了25%。</li>

                </ul>
            </div>

            <div className="w-1/2 bg-blue-100 mx-auto p-6 mb-6">
                <p className="text-lg font-bold mb-6">第四题</p>
                <p className="font-bold">作答</p>
                <p className="mb-6"> {resp[3][0]} <br /> {resp[3][1]} </p>
                <p className="font-bold">正确作答典型样例</p>
                <ul className="list-disc mb-6">
                    <li>[...增加] 接种疫苗的人阻止了疾病的扩散。</li>
                    <li>[...增加] 健康的人不会得这种病。</li>
                    <li>[...增加] 没有接种疫苗的人受身边接种过疫苗的人的保护。</li>
                    <li>[未选择] 更多的人获得疫苗，那么疾病传染和得病的人也会变少。</li>
                    <li>[...增加] 因为易传染人群和病人的联系越来越少。</li>
                    <li>[...增加] 随着接种疫苗的人越来越多，不是所有易感染人群都会得这种病。</li>
                </ul>
                <p className="font-bold">错误作答典型样例</p>
                <ul className="list-disc">
                    <li>[...增加] 蓝色的线开始向上走。</li>
                    <li>[...减少] 你不能传染任何人。</li>
                    <li>[...减少] 因为易传染人数越来越少。</li>
                    <li>[...增加] 因为你可能在接种疫苗的时候生病。</li>
                    <li>[...增加] 蓝色的线越来越高。</li>
                </ul>
            </div>

            <div className="w-1/2 bg-blue-100 mx-auto p-6 mb-6">
                <p className="text-lg font-bold mb-6">第五题</p>

                <p className="font-bold">作答</p>
                <div className="form-control mb-6">
                    <label>
                        {resp[4][0]? <input type="checkbox" className="mr-2" checked readOnly/> : <input type="checkbox" className="mr-2" disabled/>}
                        可以帮助预测疾病在人群中传播的速度。
                    </label>
                    <label>
                        {resp[4][1]? <input type="checkbox" className="mr-2" checked readOnly/> : <input type="checkbox" className="mr-2" disabled/>}
                        可以帮助确定造成疾病爆发的病毒的遗传密码。
                    </label>
                    <label>
                        {resp[4][2]? <input type="checkbox" className="mr-2" checked readOnly/> : <input type="checkbox" className="mr-2" disabled/>}
                        可以帮助检测疾病症状。
                    </label>
                    <label>
                        {resp[4][3]? <input type="checkbox" className="mr-2" checked readOnly/> : <input type="checkbox" className="mr-2" disabled/>}
                        可以帮助估计将被感染的人口比例。
                    </label>
                </div>

                <p className="font-bold">正确作答典型样例</p>
                <div className="form-control">
                    <label>
                        <input type="checkbox" className="mr-2" checked readOnly/>
                        可以帮助预测疾病在人群中传播的速度。
                    </label>
                    <label>
                        <input type="checkbox" className="mr-2" disabled/>
                        可以帮助确定造成疾病爆发的病毒的遗传密码。
                    </label>
                    <label>
                        <input type="checkbox" className="mr-2" disabled/>
                        可以帮助检测疾病症状。
                    </label>
                    <label>
                        <input type="checkbox" className="mr-2" checked readOnly/>
                        可以帮助估计将被感染的人口比例。
                    </label>
                </div>
            </div>

            <div className="w-1/2 bg-base-100 mx-auto p-6 mb-6">
                <p className="text-lg font-bold">
                    说明：由于测量模型和数据处理的限制，无法实时生成详细评价报告，仅提供以上结果供您自评参考。
                </p>
            </div>

        </div>
    );
}

export default Report;
