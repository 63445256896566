import './index.css';
import { MenuAlt1Icon } from '@heroicons/react/outline'

function Navbar( {page, setPage} ) {
    return (
        <div className="navbar bg-base-100">
            <div className="navbar-start">
                <div className="dropdown">
                    <label tabIndex="0" className="btn btn-ghost lg:hidden">
                        <MenuAlt1Icon className="h-5 w-5" />
                    </label>
                    <ul tabIndex="0"
                        className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                        <li className={(page < 0)? " disabled" : ""}><button onClick={() => {setPage(0)}}>欢迎页</button></li>
                        <li className={(page < 1)? " disabled" : ""}><button onClick={() => {setPage(1)}}>考试投送</button></li>
                        <li className={(page < 2)? " disabled" : ""}><button onClick={() => {setPage(2)}}>智能评价</button></li>
                    </ul>
                </div>
                <button className="btn btn-ghost normal-case text-xl">多媒体动态题型</button>
            </div>
            <div className="navbar-end hidden lg:flex">
                <ul className="menu menu-horizontal rounded-box">
                    <li className={(page < 0)? " disabled" : ""}>
                        <button className='px-10 font-bold' disabled={page < 0} onClick={() => {setPage(0)}}>欢迎页</button>
                    </li>
                    <li className={(page < 1)? " disabled" : ""}>
                        <button className='px-10 font-bold' disabled={page < 1} onClick={() => {setPage(1)}}>考试投送</button>
                    </li>
                    <li className={(page < 2)? " disabled" : ""}>
                        <button className='px-10 font-bold' disabled={page < 2} onClick={() => {setPage(2)}}>智能评价</button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;
