import React from 'react';
import './index.css';

function Delivery({ time, resp, setPage, setTime, setResp }) {
    const [qid, setQid] = React.useState(0);

    function Button({dest, text}) {
        return (
            <button type="button"
                    style={{backgroundColor: '#D9E5C1'}}
                    className="rounded ml-4 inline-block px-2 py-2.5 text-black font-medium text-sm leading-tight uppercase hover:bg-emerald-200 focus:bg-emerald-200 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                    onClick={() =>
                    {
                        if (dest !== qid) {
                            let newResp = resp.slice();
                            const respDOM = document.getElementById('left').contentDocument;
                            if (qid === 2) {
                                newResp[0] = [
                                    respDOM.getElementById('S634Q01checkbox_1').checked,
                                    respDOM.getElementById('S634Q01checkbox_2').checked,
                                    respDOM.getElementById('S634Q01checkbox_3').checked,
                                    respDOM.getElementById('S634Q01checkbox_4').checked,
                                    respDOM.getElementById('S634Q01checkbox_5').checked
                                ];
                            }
                            else if (qid === 3) {
                                const DragDropObj = document.getElementById('left').contentWindow.DragDropObj;
                                let Drop1, Drop2, Drop3;
                                if (DragDropObj.targetHasOption('destination1', 'source1')) {
                                    Drop1 = 1;
                                } else if (DragDropObj.targetHasOption('destination1', 'source2')) {
                                    Drop1 = 2;
                                } else if (DragDropObj.targetHasOption('destination1', 'source3')) {
                                    Drop1 = 3;
                                } else {
                                    Drop1 = 0;
                                }
                                if (DragDropObj.targetHasOption('destination2', 'source1')) {
                                    Drop2 = 1;
                                } else if (DragDropObj.targetHasOption('destination2', 'source2')) {
                                    Drop2 = 2;
                                } else if (DragDropObj.targetHasOption('destination2', 'source3')) {
                                    Drop2 = 3;
                                } else {
                                    Drop2 = 0;
                                }
                                if (DragDropObj.targetHasOption('destination3', 'source1')) {
                                    Drop3 = 1;
                                } else if (DragDropObj.targetHasOption('destination3', 'source2')) {
                                    Drop3 = 2;
                                } else if (DragDropObj.targetHasOption('destination3', 'source3')) {
                                    Drop3 = 3;
                                } else {
                                    Drop3 = 0;
                                }

                                newResp[1] = [
                                    Drop1, Drop2, Drop3,
                                    respDOM.getElementById('S620Q00SELECT1').options[respDOM.getElementById('S620Q00SELECT1').selectedIndex].text,
                                    respDOM.getElementById('S620Q00SELECT2').options[respDOM.getElementById('S620Q00SELECT2').selectedIndex].text
                                ];
                            }
                            else if (qid === 4) {
                                const radio31 = respDOM.getElementById('CS634Q03RADIO_0').checked;
                                const radio32 = respDOM.getElementById('CS634Q03RADIO_1').checked;
                                const text3 = respDOM.getElementById('CS634Q03TXT').value;
                                newResp[2] = [
                                    (!radio31 && !radio32)? "未选择X病的传染性更强或更弱" : (radio31? "X病的传染性更强" : "X病的传染性更弱"),
                                    text3===''? '空白作答' : text3
                                ];
                            }
                            else if (qid === 5) {
                                const radio41 = respDOM.getElementById('CS634Q04RADIO_0').checked;
                                const radio42 = respDOM.getElementById('CS634Q04RADIO_1').checked;
                                const text4 = respDOM.getElementById('CS634Q04TXT').value;
                                newResp[3] = [
                                    (!radio41 && !radio42)? "未选择易感个体数目增加或降低" : (radio41? "易感个体数目增加" : "易感个体数目降低"),
                                    text4===''? '空白作答' : text4
                                ];
                            }
                            else if (qid === 6) {
                                newResp[4] = [
                                    respDOM.getElementById('CS634Q05checkbox_1').checked,
                                    respDOM.getElementById('CS634Q05checkbox_2').checked,
                                    respDOM.getElementById('CS634Q05checkbox_3').checked,
                                    respDOM.getElementById('CS634Q05checkbox_4').checked
                                ];
                            }
                            setResp(newResp);
                            if (dest >= 0 && dest <= 6) {
                                setTime(time.slice().concat([new Date(), dest]));
                                setQid(dest);
                            }
                            else if (dest === -2) {
                                setTime(time.slice().concat([new Date()]));
                                setQid(0);
                                setPage(2);
                            }
                        }
                    }}>
                {text}
            </button>
        );
    }

    const qidLeft = {
        0: ['question00.html', { width: '1007px', height: '706px' }],
        1: ['question000.html', { width: '399px', height: '706px' }],
        2: ['question1.html', { width: '399px', height: '706px' }],
        3: ['question2.html', { width: '399px', height: '706px' }],
        4: ['question3.html', { width: '399px', height: '706px' }],
        5: ['question4.html', { width: '399px', height: '706px' }],
        6: ['question5.html', { width: '1007px', height: '706px' }]
    };

    const qidRight = {
        0: ['stimulus0.html', { width: '0px', height: '706px' }],
        1: ['stimulus.html', { width: '608px', height: '706px' }],
        2: ['stimulus.html', { width: '608px', height: '706px' }],
        3: ['stimulus.html', { width: '608px', height: '706px' }],
        4: ['stimulus.html', { width: '608px', height: '706px' }],
        5: ['stimulus.html', { width: '608px', height: '706px' }],
        6: ['stimulus0.html', { width: '0px', height: '706px' }]
    };

    return (
        <div className="bg-base-100 p-6">
            <div style={{ width: '1007px'}} className="mx-auto p-2 bg-sky-700 text-white flex">
                <div className="justify-start" role="toolbar">
                    <Button dest={0} text="背景"/>
                    <Button dest={1} text="模拟"/>
                    <Button dest={2} text="问题1"/>
                    <Button dest={3} text="问题2"/>
                    <Button dest={4} text="问题3"/>
                    <Button dest={5} text="问题4"/>
                    <Button dest={6} text="问题5"/>
                </div>

                <div className="ml-auto" role="toolbar">
                    <Button dest={qid-1} text="上一题"/>
                    <Button dest={qid+1} text="下一题"/>
                    <Button dest={-2} text="提交"/>
                </div>
            </div>
            <div className="mx-auto flex justify-center">
                <iframe
                    title="left"
                    id = "left"
                    src={'https://' + window.location.hostname + '/vaccination/zho-CHN/' + qidLeft[qid][0]}
                    style={qidLeft[qid][1]}
                />
                <iframe
                    title="right"
                    id = "right"
                    key={qid}
                    src={'https://' + window.location.hostname + '/vaccination/zho-CHN/' + qidRight[qid][0]}
                    style={qidRight[qid][1]}
                />
            </div>
        </div>
    );
}

export default Delivery;
