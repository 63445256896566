import React from 'react';
import Hero from './Hero'
import './index.css';
import Navbar from "./Navbar";
import Delivery from "./Delivery";
import Report from './Report';

function App() {
    const [page, setPage] = React.useState(0);
    const [time, setTime] = React.useState([]);
    const [resp, setResp] = React.useState([]);

    React.useEffect(() => {
        if (page === 1) {
            setTime([0, new Date()]);
            setResp([
                [false, false, false, false, false],
                [0, 0, 0, '请选择', '请选择'],
                ['未选择X病的传染性更强或更弱', '空白作答'],
                ['未选择易感个体数目增加或降低', '空白作答'],
                [false, false, false, false]
            ]);
        }
    }, [page]);

    if (page === 0) {
        return <Hero setPage={setPage} />;
    }
    else if (page === 1) {
        return (
            <>
                <Navbar page={1} setPage={setPage} />
                <Delivery time={time} resp={resp} setPage={setPage} setTime={setTime} setResp={setResp} />
            </>
        );
    } else {
        return (
            <>
                <Navbar page={2} setPage={setPage} />
                <Report time={time} resp={resp} />
            </>
        );
    }
}

export default App;
